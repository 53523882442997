import { FieldHookConfig, Form, Formik, useField } from "formik";
import { ClassAttributes, InputHTMLAttributes, useContext } from "react";
import { motion } from "framer-motion";
import { services } from "../..";
import Smiler from "../../components/Icons/Smiler";
import * as Yup from "yup";
import { ViewContext } from ".";

type TextFieldProps = {
  label: string;
};

const validation = Yup.object({
  name: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
});

const TextField = ({
  label,
  ...props
}: TextFieldProps &
  InputHTMLAttributes<HTMLInputElement> &
  ClassAttributes<HTMLInputElement> &
  FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  return (
    <div style={{ marginTop: 20 }}>
      <label
        style={{
          color: field.value ? "lime" : "white",
          transition: "color 500ms",
        }}
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};
type FormValues = {
  name: string;
  phone: string;
};

export default function Signup() {
  const { nextView } = useContext(ViewContext);
  return (
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      className="container"
    >
      <Formik
        initialValues={{
          name: "",
          phone: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const { name, phone } = values;
          const res = await services.leaveNameAndNumber(name, phone);
          if (res) {
            nextView();
          }
          setSubmitting(false);
        }}
        validationSchema={validation}
      >
        {({ isSubmitting }) => (
          <Form className="form">
            <TextField
              label="Name"
              name="name"
              type="text"
              placeholder="Whatever you call yourself"
            />
            <TextField
              label="Phone number"
              name="phone"
              type="tel"
              autoComplete="tel"
              placeholder="A number to text you for a reminder"
            />
            <button className="" type="submit">
              <motion.div layout>
                {isSubmitting ? (
                  <motion.div
                    initial={{ scale: 0.7 }}
                    animate={{ scale: 1 }}
                    style={{ width: 100, height: 100 }}
                    transition={{
                      repeat: Infinity,
                      duration: 0.5,
                      repeatType: "reverse",
                    }}
                  >
                    <Smiler />
                  </motion.div>
                ) : (
                  "RSVP"
                )}
              </motion.div>
            </button>
          </Form>
        )}
      </Formik>
    </motion.div>
  );
}
