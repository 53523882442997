import { createContext, useState } from "react";
import Initial from "./Initial";
import Signup from "./Signup";
import Details from "./Details";
import Complete from "./Complete";

import { motion } from "framer-motion";

import "./index.css";

enum View {
  Initial,
  Details,
  RSVP,
  Complete,
}

export const ViewContext = createContext({
  view: View.Initial,
  nextView: () => {},
});

export default function () {
  const [view, setView] = useState(View.Initial);

  const nextView = () => setView(view + 1);
  return (
    <motion.div layout className="sauce">
      <div className="title">Fred again... Hunt</div>
      <ViewContext.Provider value={{ view, nextView }}>
        {view === View.Initial && <Initial />}
        {view === View.Details && <Details />}
        {view === View.RSVP && <Signup />}
        {view === View.Complete && <Complete />}
      </ViewContext.Provider>
    </motion.div>
  );
}
