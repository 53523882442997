import { oracleTavernGmapsLink } from "./constants";
import { motion } from "framer-motion";
import { ViewContext } from ".";
import { useContext } from "react";

export default function Details() {
  const { nextView } = useContext(ViewContext);
  return (
    <>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        className="container flex-center"
      >
        <div className="details md"> Starts @</div>
        <div className="details md"> La Colombe in Frog Town</div>

        <div className="details md"> September 25th</div>
        <div className="details md">2pm - 3pm~</div>
        <div className="details md" style={{ marginTop: 30 }}>
          Win a pair of tix to Fred again... @ Hollywood Forever Friday show
        </div>
        <div className="details md" style={{ marginTop: 30 }}>
          Hang out afterwards around my studio
        </div>
      </motion.div>
      <button className="" style={{ fontSize: "1.8rem" }} onClick={nextView}>
        Let's do it
      </button>
    </>
  );
}
