import { useContext } from "react";
import { ViewContext } from ".";

export default function Initial() {
  const { nextView } = useContext(ViewContext);
  return (
    <div style={{ paddingTop: 0 }}>
      <div style={{ marginBottom: 20, padding: 50 }} className="details">
        Are you free on September 25th?
      </div>
      <button className="bottom" onClick={nextView}>
        Yep
      </button>
    </div>
  );
}
