import { fredCalLink, oracleTavernGmapsLink } from "./constants";
import { motion } from "framer-motion";

export default function Complete() {
  return (
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      className="container flex-center"
    >
      {/* <div className="title" style={{ color: "grey" }}>
        Details
      </div>{" "} */}
      {/* <a
        href={oracleTavernGmapsLink}
        target="__blank"
        rel="nonreferrer"
        className="details"
      >
        Oracle Tavern
      </a> */}
      <div className="details md"> Starts @</div>
      <div className="details md"> La Colombe in Frog Town</div>
      <div className="details md"> September 25th</div>
      <div className="details md">2pm - ???</div>
      <div className="details md" style={{ marginTop: 50 }}>
        Win a pair of tix to Fred again... @ Hollywood Forever Friday show
      </div>
      <a
        href={fredCalLink}
        target="__blank"
        rel="nonreferrer"
        className="details"
        style={{ fontSize: "1.5rem", marginTop: 50 }}
      >
        Add to your calendar
      </a>
    </motion.div>
  );
}
